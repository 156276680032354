.columns
  margin-left: 0
  margin-right: 0
  margin-top: 0

.button-row
  padding-bottom: 1.5rem
  padding-top: 1rem
  button:first-child
    margin-right: 0.7rem

.is-warning
  color: #9B1A06
  margin-left: 10px

.is-okay
  color: #2CA895

.button-row
  display: flex
  justify-content: space-between

.select-filters
  width: 212px
  height: 44px
  margin-right: 15px
  background-color: rgba(51, 51, 51, 0.06)
  border-radius: 2px
  border: none
  outline: none
  border-bottom: 1px solid

.right-buttons-container
  display: flex
  justify-content: space-between

hr
  background-color: #979797
  height: 1px

.title
  margin-bottom: 0
