.columns
  margin-left: 0
  margin-right: 0
  margin-top: 0

.is-warning
  color: #9B1A06
  margin-left: 8px

.is-okay
  color: #2CA895

.select-filters
  width: 212px
  height: 44px
  margin-right: 16px
  background-color: rgba(51, 51, 51, 0.06)
  border-radius: 2px
  border: none
  outline: none
  border-bottom: 1px solid

.title
  margin-bottom: 0
