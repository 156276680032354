@import "../../styles/animations.scss"

.loadercontainer
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

.loadercontainer-hide
    display: none

.children-container-hide
    display: none

.children-container
    height: 100%
    opacity: 1
    animation: fadeIn linear 0.3s

.hideloader
    display: none

#loader-container
    max-width: 500px
    height: auto
