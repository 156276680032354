.containerForm
    padding: 16px

hr
    background-color: #D8D8D8

.form-container
    margin: 0 20px

.input[disabled]
    background-color: #fff
    opacity: .3