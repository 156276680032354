.more-button-container
  display: flex
  align-items: center

.empty-row
  height: 24px
  width: 24px
  display: flex
  align-items: center
  justify-content: center


th > span > div
  max-width : 80px
  overflow: hidden
  text-overflow: ellipsis

.align-center
    text-align: center
