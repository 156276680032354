@import "react-widgets/scss/styles.scss"

html
    background-color: #fff
    font-size: 16px
    -moz-osx-font-smoothing: grayscale
    -webkit-font-smoothing: antialiased
    min-width: 300px
    overflow-x: hidden
    overflow-y: scroll
    text-rendering: optimizeLegibility
    -webkit-text-size-adjust: 100%
    -moz-text-size-adjust: 100%
    text-size-adjust: 100%

body
    margin: 0
    font-family: "Roboto", sans-serif, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"

body::-webkit-scrollbar
  display: none

a
    text-decoration: none

ul
    padding: 0

li
    list-style-type: none

.container
    padding: 16px
    display: flex
    justify-content: space-between

[class^="icon-"], [class*=" icon-"]
    /* use !important to prevent issues with browser extensions that change fonts */
    speak: none
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.icon-screen-accept:before
    content: "\e900"

.icon-screen-reject:before
    content: "\e901"

.icon-user-accept:before
    content: "\e902"

.icon-user-reject:before
    content: "\e903"

h1,
h2,
h3,
h4,
h5,
h6
  font-family: "Muli", sans-serif
  padding: 0
  padding-bottom: 8px
  line-height: 1.375em
  margin: 0

h1
  font-size: 1.75em
  font-weight: 600
h2
  font-size: 1.375em
  font-weight: 600
h3
  font-size: 1.125em
  i
    vertical-align: middle
    margin-top: -2px

h4
  font-size: 1em
  font-weight: 600
h5
  font-size: 0.875em
h6
  font-size: 0.75em