h1
    margin-bottom: 16px

.no-task 
    max-width: calc( 100% - 16.6% )

.button-row
  padding-bottom: 1.5rem
  padding-top: 1rem
  display: flex
  justify-content: space-between

label
  font-size: 14px
  font-weight: 500
  color: #666666
  margin-bottom: 4px
  display: flex
  align-items: center

.search-bar
  display: flex
  justify-content: flex-end
  width: 400px
  box-shadow: 0px 1px 2px 0px rgba(41, 41, 41, 0.6)

.search-input
  background-color: white
  width: 400px
  padding: 8px
  border-radius: 2px

.container-worklist-page
    padding: 16px
    justify-content: space-between

.btn
  font-family: "Roboto", sans-serif, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"
  border: none
  border-radius: 2px
  display: flex
  align-content: center
  min-width: 150px
  cursor: pointer

  min-height: 44px
  -webkit-justify-content: center
  justify-content: center
  align-items: center
  padding: 4px 16px
  text-align: center

  -webkit-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  -moz-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  transition: background-color 0.3s ease
  font-size: 16px
  font-weight: 500
  color: #ffffff
  text-transform: uppercase

  &.primary
      background-color: #2CA895
      &:hover
          background-color: darken(#2CA895, 10%)

  &.secondary
      background-color: #3C73B8
      &:hover
          background-color: darken(#3C73B8, 10%)

  &.tertiary
      background-color: #666666
      &:hover
          background-color: darken(#666666, 10%)
          color: #ffffff

  &.quaternary
      background-color: darken(#f2f2f2, 10%)
      color: #292929
      &:hover
          background-color: #f2f2f2

  &.denial
      background-color: #9B1A06
      &:hover
          background-color: darken(#9B1A06, 10%)

  &.inactive
      opacity: 0.4
      cursor: not-allowed

  &.white
      box-shadow: none
      color: #292929
      min-width: auto

.view
    background: transparent
    padding: 5px 10px
    border: 1px solid #666666
    border-radius: 5px
    font-size: 14px
    margin-left: 5px
    color: #666666
    cursor: pointer

    i
        font-size: 16px
        margin-right: 0
        vertical-align: middle