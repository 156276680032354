.weight-medium
  font-weight: 600
  
.is-warning
  color: #9B1A06

.is-okay
  color: #2CA895

progress
  border-radius: 2px
  width: 100px
  height: 8px

progress::-webkit-progress-bar
  background-color: #f2f2f2
  border-radius: 10px

progress::-webkit-progress-value
  border-radius: 10px
  background-color: #2CA895

i
  margin-right: 10px

hr
  margin: .85rem 0
