.columns
    width: 100%
    margin-left: 0
    margin-right: 0
    margin-top: 0
    display: flex
    flex-direction: row

.app-container
    width: calc( 100% - 16.6% )

.app-container-no-sidebar
    width: 100%