.buttons-container
  display: flex
  flex-flow: row
  justify-content: space-between

.helper-text
  color: #666666
  font-size: 12px
  margin-top: 10px

.email-field
  background-color: rgba(255, 255, 255, .5)
