.paragraph 
  margin-bottom: "8px",
  margin-top: "8px"


//***************************
// CREATE STUDY BUTTON
//***************************

.btn
  font-family: "Roboto", sans-serif, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"
  border: none
  border-radius: 2px
  display: flex
  align-content: center
  min-width: 150px
  cursor: pointer

  min-height: 44px
  -webkit-justify-content: center
  justify-content: center
  align-items: center
  padding: 4px 16px
  text-align: center

  -webkit-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  -moz-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
  transition: background-color 0.3s ease
  font-size: 16px
  font-weight: 500
  color: #ffffff
  text-transform: uppercase

  &.primary
      background-color: #2CA895
      &:hover
          background-color: darken(#2CA895, 10%)

  &.secondary
      background-color: #3C73B8
      &:hover
          background-color: darken(#3C73B8, 10%)

  &.tertiary
      background-color: #666666
      &:hover
          background-color: darken(#666666, 10%)
          color: #ffffff

  &.quaternary
      background-color: darken(#f2f2f2, 10%)
      color: #292929
      &:hover
          background-color: #f2f2f2

  &.denial
      background-color: #9B1A06
      &:hover
          background-color: darken(#9B1A06, 10%)

  &.inactive
      opacity: 0.4
      cursor: not-allowed

  &.white
      box-shadow: none
      color: #292929
      min-width: auto

.button-row
  padding-bottom: 1.5rem
  padding-top: 1rem
  display: flex
  justify-content: space-between

//***************************
// TABLE
//***************************

.root
    margin: auto

.header-cell
    font-size: 0.875rem

.body-row
    cursor: pointer

.body-cell
    span
        font-size: 1rem

.tag
  align-items: center
  display: inline-flex
  color: white
  background-color: transparent
  text-transform: capitalize
  font-size: 1rem
  padding-left: 0
  height: 2em

.is-active
    color: #2CA895

.align-center
    text-align: center

.bullet
    font-size: 24px
    margin-right: 5px

.activeSortIcon
    opacity: '1'

.inactiveSortIcon
    visibility: 'visible',
    opacity: '0.5'

.container-project-list-page
    padding: 16px
    justify-content: space-between