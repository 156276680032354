body, button, input, select, textarea
    font-family: "Roboto",sans-serif,"Segoe UI","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial"

label
  font-size: 14px
  font-weight: 500
  color: #666666
  margin-bottom: 4px
  display: block

option
    cursor: pointer
    padding: 10px

    &:hover
        background-color: #f2f2f2

.formContainer
    margin-top: 32px

.formItem
    margin-bottom: 32px

.field:not(:last-child)
    margin-bottom: 0px

.input, textarea
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%)
    max-width: 100%
    width: 100%
    background-color: #fff
    border-radius: 4px
    color: #363636
    -webkit-appearance: none
    align-items: center
    border: 1px solid transparent
    display: inline-flex
    font-size: 1rem
    justify-content: flex-start
    line-height: 1.5
    padding: calc(0.5em - 1px) calc(0.75em - 1px)
    position: relative
    vertical-align: top
    border-color: #dbdbdb
    margin: 0

.textarea 
  min-height: 150px

.buttonsContainer
  display: flex
  justify-content: space-between

  .btn
      font-family: "Roboto", sans-serif, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"
      border: none
      border-radius: 2px
      display: flex
      align-content: center
      min-width: 150px
      cursor: pointer

      min-height: 44px
      -webkit-justify-content: center
      justify-content: center
      align-items: center
      padding: 4px 16px
      text-align: center

      -webkit-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
      -moz-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
      box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
      transition: background-color 0.3s ease
      font-size: 16px
      font-weight: 500
      color: #ffffff
      text-transform: uppercase

      &.primary
          background-color: #2CA895
          &:hover
              background-color: darken(#2CA895, 10%)

      &.secondary
          background-color: #3C73B8
          &:hover
              background-color: darken(#3C73B8, 10%)

      &.tertiary
          background-color: #666666
          &:hover
              background-color: darken(#666666, 10%)
              color: #ffffff

      &.quaternary
          background-color: darken(#f2f2f2, 10%)
          color: #292929
          &:hover
              background-color: #f2f2f2

      &.denial
          background-color: #9B1A06
          &:hover
              background-color: darken(#9B1A06, 10%)

      &.inactive
          opacity: 0.4
          cursor: not-allowed

      &.white
          box-shadow: none
          color: #292929
          min-width: auto