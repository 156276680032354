.title
  paddingBottom: "8px"

.paragraph
  paddingBottom: "8px"

.titleDescription
  marginBottom: "10px"

.cards-left-container
  padding-right: 16px

.button-right-container
  display: flex
  justify-content: flex-end
  margin: 8px 0 0
  padding: 8px 0 0

.container-study-overview-page
    padding: 16px
    justify-content: space-between

.ghost-btn
    cursor: pointer
    background: #ffffff
    padding: 8px
    border: 1px solid #292929
    border-radius: 2px
    font-size: 12px
    font-weight: 500
    color: #292929
    min-height: 32px
    vertical-align: middle
    line-height: 18px

    &:hover
      cursor: pointer

    i
      font-size: 18px
      vertical-align: middle
      margin-top: -2px
      margin-right: 4px