.sidepanel-category
    color: #8F9194
    font-size: 12px
    text-transform: uppercase
    margin-top: 10px

    &:after
        background-color: #ccc
        content: ""
        display: inline-block
        height: 1px
        position: relative
        vertical-align: middle
        width: 70%
        margin-left: 10px


.dashboard-side-panel
    background: #2CA895
    background-color: #2CA895
    max-width: 16.6%
    width: 100%
    z-index: 1000
    border-right: 1px solid #DEDEDE
    display: block
    padding: 3.5rem 2.5rem
    min-height: 100vh
    align-items: center
    justify-content: flex-start
    .tab-link
        padding: 0.625rem 0rem
        list-style-type: none

        a
            color: white

            &:before
                content: '\00a0'
                padding-right: 0.5rem

        &.is-active
            font-weight: bold

            &:before
                content: '\2022'
                color: white
                transform: scale(1.6)

.project-side-panel
    background: #ffffff
    background-color: #ffffff
    max-width: 16.6%
    width: 100%
    z-index: 1000
    border-right: 1px solid #DEDEDE
    display: block
    padding: 3.5rem 2.5rem
    min-height: 100vh
    align-items: center
    justify-content: flex-start
    a
        color: #292929
        display: block
        &.is-active
            background-color: #f2f2f2
            margin: 0 -2.5rem
            padding-left: 2.5rem
            color: #2CA895
            &.tab-link
                color: #292929
                list-style-type: none

        .tab-link
            padding: 0.625rem 0rem 
            list-style-type: none
    
    .dashboard-link
      display: block
      padding-top: 1rem
    
      a
        color: #279181
        opacity: 0.7
        
        &:before
          text-decoration: none
          content: "\003C"
          transform: scale(1.6)
          padding-right: 0.5rem
        
        &:hover
          opacity: 1
