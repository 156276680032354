.weight-medium
  font-weight: 600

.info-container
    line-height: 1.5
    display: flex
    justify-content: space-between
    padding: 10px 0
    border-bottom: 1px solid #CCC
    &:last-child
      border: none

    p 
        margin: 0

.info-description
    font-size: 16px
    font-family: "Roboto",sans-serif
    font-weight: 400
    line-height: 1.5