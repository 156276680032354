.buttons-container
    display: flex
    flex-flow: row
    justify-content: space-between
    padding-left: 0px

.helper-text
    color: #666666
    font-size: 12px
    margin-top: 10px

.input[disabled]
    border: 1px solid #C9C9C9

.field
    padding-left: 0px
